import React from "react";

import one from "../../../assets/sectionthree/one.png";
import v2 from "../../../assets/hero/v2.jpg";

import "./SectionThree.scss";

const SectionThree = () => {
  return (
    <div className="container common_section_padding">
      <div className="s3_header text-center title_padding">
        <h6 className="cc_text_transform">Our Priority</h6>
        <h3 className="font_weight_800">Adding Value for You</h3>
      </div>
      <div className="container">
        <div className="s3_body row">
          <div className="col-lg-6 s3_left">
            <div className="s3_body_heading">
              <h6 className="cc_text_transform white">An alliance of cost Accountants

</h6>
              <h2 className="font_weight_600">
              We believe in continuous development and collaborative growth.
              </h2>
            </div>
            <p>
            The firm represents a combination of specialized skills, which are geared to offers sound financial advice and personalized proactive services. Following the top corporate culture, we believe in delivering accurate, relevant and timely information to the decision makers using industry expertise.

            </p>
          </div>
          <div className="col-lg-6 s3_right">
            <img data-aos="fade-up" src={v2} alt="one" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
