import React from "react";
import logos from "../../assets/logos.jpg";

import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <footer className="footer_container">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-4">
              <div className="left left_left">
                <img onClick={() => navigate("/")} src={logos} alt="logo" />
                <p>
                  Connect with us.
                </p>
                <div className="footer_social_media_icons">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/jmassociates"
                  >
                    <FaFacebookF />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/jmassociates"
                  >
                    <FaTwitter />
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href=" https://www.instagram.com/jmassociates/"
                  >
                    <FaInstagram />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="left left_right">
                <h5>Navigation</h5>
                <p onClick={() => navigate("/features")}>Services </p>
                <p onClick={() => navigate("/about-us")}>About us </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="right right_left">
                <h5>Office Address</h5>
                <p class = "footer-content">Head Office: ||| B/26, BJB NAGAR, NEAR PARAMOUNT CLUB,
		KALPANA, BHUBANESWAR,
		KHORDA – 751014 (Odisha)
</p>
<p class>Branch Office1:
    GC-3,Narayantala,West kolkota 101,Prafulla kanan,West bengal-700101
</p>
<p>Branch Office2:
    Joy apartment,2nd floor,hindustan park,street no-1,Asansol,West bengal-713304
</p>
<p>Branch Office3:
    202B,Aratt royal castle apartment,manipal county road,Lake view country,singsandra,Begur,Banglore,Karnataka-560114
</p>
<p>Branch Office4:
    plot no-765A,sector10A, Near sadvabhana park,Gurgaon,Haryana-122001
</p>
<p class>Branch Office5:
    Bijipur,kalka sahi,Berhampur sadar, Berhampur-760001 (Odisha)
</p>
<p class>Branch Office6:
    Khirstian  Para, Near Bapitsed Church
Sambalpur – 768001 (Odisha)
</p>
<p class>Branch Office7:
    Baghajatin market complex,ITI square,near irrigation office, Baleswar-756001 (Odisha)
</p>
<p class>Branch Office8:
    plotno-2826/5456,Anand bazar,Talacher thermal,Talcher-759101 (Odisha)
</p>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="right left_right">
                <h5>Info</h5>


                  <p> Email: jmassociates.cma@gmail.com </p>
                  <p> Phone: 9337473533 / 94373088852 </p>

              </div>
            </div>
            <div className="footer_copyright col-lg-12 text-center">
              ©2022 JM & Associate. All Rights Reserved.
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
