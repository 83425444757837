import React from "react";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { Link } from "react-router-dom";
import "./Header.scss";

const Header = () => {
  return (
    <div className="header_bg">
      <div className="header_container container">
        <div className="header_back_btn">
          <Link to="/home" className="feature_link header_left">
            <MdOutlineArrowBackIosNew className="header_back_icon" />
            <h5>Citizenchat Coin</h5>
          </Link>
        </div>
        <div className="header_back_btn">
          <a href="#" className="primary_btn">
            Download Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
