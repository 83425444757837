import React from "react";
import Slider from "react-slick";
import face1 from "../../../assets/sectionfive/face1.png";
import face2 from "../../../assets/sectionfive/face2.png";
import face3 from "../../../assets/sectionfive/face3.png";
import face4 from "../../../assets/sectionfive/face4.png";
import face5 from "../../../assets/sectionfive/face5.png";
import face6 from "../../../assets/sectionfive/face6.png";
import face7 from "../../../assets/sectionfive/face7.png";
import face8 from "../../../assets/sectionfive/face8.png";
import all from "../../../assets/sectionfive/all.png";
import comma from "../../../assets/crousel/comma.png";
import "./SectionFive.scss";

const crouselData = [
  {
    comma: comma,
    content:
      "If you want go to one of the best CMA firms then here is what you want. They provide a great help and excellent service and are highly knowledgeable persons.",
    name: "NESCO",
    about: "Electric utility company ",
  },
  {
    comma: comma,
    content:
      "This is one of the best CMA firm in Bhubaneswar.All the professional are very humble and sincere.I have 1 year relationship with this CMA firm.",
    name: "GRIDCO",
    about: "Electric power transmission company",
  },
  {
    comma: comma,
    content:
      "It's a complete set of solutions for taxation and Accounts. We can get quick solution in a smarter way. I really appreciate the way of services delivered here.",
    name: "CIPET",
    about: "Central Institute of Petrochemicals Engineering & Technology",
  },

  {
    comma: comma,
    content:
    "One of the best Accounts & Audit firm in Bhubaneswar.",
    name: "OCC",
    about: "Financial services company",
  },
];

const SectionFive = () => {
  var settings = {
    arrows: false,
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="common_section_padding">
      <div className="s5_container">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="s5_left common_heading">
                <h6 className="cc_text_transform white">WE HELP YOU TO</h6>
                <h3 className="font_weight_800 h3_plus">
                  Thousands of Happy Stories
                </h3>
                <div>
                  <Slider {...settings}>
                    {crouselData.map((cd, index) => (
                      <div key={index} className="crusel_container">
                        <img src={comma} alt="comma" />
                        <p>{cd.content}</p>
                        <div className="crusel_drag">
                          <div>
                            <h6>{cd.name}</h6>
                            <p>{cd.about}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>

            {window.innerWidth > 1050 ? (
              <div className="col-lg-6 ">
                <div className="s5_right">
                  <img src={face1} alt="face1" />
                  <img src={face2} alt="face2" />
                  <img src={face3} alt="face3" />
                  <img src={face4} alt="face4" />
                  <img src={face5} alt="face5" />
                  <img src={face6} alt="face6" />
                  <img src={face7} alt="face7" />
                  <img src={face8} alt="face8" />
                </div>
              </div>
            ) : (
              <div className="col-lg-6 s5_all_image">
                <img data-aos="flip-left" src={all} alt="face8" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFive;
