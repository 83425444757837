import React from "react";
import Footer from "../../components/Footer/Footer";
import Hero from "../../components/ForHome/Hero/Hero";
import SectionFive from "../../components/ForHome/SectionFive/SectionFive";
import SectionFour from "../../components/ForHome/SectionFour/SectionFour";
import SectionOne from "../../components/ForHome/SectionOne/SectionOne";
import SectionSeven from "../../components/ForHome/SectionSeven/SectionSeven";
import SectionSix from "../../components/ForHome/SectionSix/SectionSix";
import SectionThree from "../../components/ForHome/SectionThree/SectionThree";
import SectionTwo from "../../components/ForHome/SectionTwo/SectionTwo";
import Navbar from "../../components/Navbar/Navbar";
import "./Home.scss";

const Home = () => {
  return (
    <>
      <div className="common_bg">
        <Navbar />
        <Hero />
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <SectionFour />
        <SectionFive />
        {/* <SectionSix /> */}
        {/* <SectionSeven /> */}
        <Footer />
      </div>
    </>
  );
};

export default Home;
