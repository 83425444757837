import React from "react";
import one from "../../assets/community/one.png";
import two from "../../assets/community/two.png";
import l1 from "../../assets/community/l1.png";
import l2 from "../../assets/community/l2.png";
import icon1 from "../../assets/aboutus/icon1.png";
import icon2 from "../../assets/aboutus/icon2.png";
import icon3 from "../../assets/aboutus/icon3.png";
import "./Community.scss";

const Community = () => {
  return (
    <>
      <div className="container common_top_padding">
        <div className="row">
          <div className="col-lg-6 flex_center">
            <div className="cmt_left">
              <h6>COMMUNITY</h6>
              <h3>
                Lorem ipsum dolor sit amet, consectr adipiscing elit. Quam vitae
                risus.
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam
                vitae risus, sed urna, sed dis adipiscing bibendum. Et enim
                augue lectus aliquam, dignissim nunc. Pellentesque cras placerat
                pellentesque diam fermentum.
              </p>
              <button className="primary_btn">Get Started</button>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="cmt_right_image">
              <img src={one} alt="hero image" />
            </div>
          </div>
        </div>
        <div className="row common_section_padding">
          <div className="text-center ">
            <h6 className="cc_text_transform">Our Priority</h6>
            <h3 className="font_weight_800">Adding Value for You</h3>
            <p>
              Full benefits of security and access controls including Data Loss
              Prevention, Compliance, <br /> Admin Settings, Vault Retention,
              Holds, Search, and Export
            </p>
            <div className="bottom_top_container">
              <div className="bottom_top_element">
                <div className="bottom_top_element_icon">
                  <img src={icon1} alt="star" />
                </div>{" "}
                <h4>Title</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt
                </p>
              </div>
              <div className="bottom_top_element">
                <div className="bottom_top_element_icon">
                  <img src={icon2} alt="star" />
                </div>
                <h4>Title</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt
                </p>
              </div>
              <div className="bottom_top_element">
                <div className="bottom_top_element_icon">
                  <img src={icon3} alt="star" />
                </div>{" "}
                <h4>Title</h4>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row common_section_padding">
          <div className="cmt_s3_left col-lg-6">
            <h6>Creators & influencers</h6>
            <h3>
              Excellent company <br /> quality services
            </h3>
            <p>
              Donec lacinia purus et nisl dignissim, vel egestas nunc iaculis.
              Maecenas elementum elementum ipsum, sit amet lacinia lectus
              vulputate nec.
            </p>
            <div className="cmt_s3_element">
              <div>
                <img src={l1} alt="icon" />
              </div>
              <p>
                Donec lacinia purus et nisl dignissim, vel egestas nunc iaculis.
                Maecenas elementum elementum ipsum.
              </p>
            </div>
            <div className="cmt_s3_element">
              <div>
                <img src={l2} alt="icon" />
              </div>
              <p>
                Donec lacinia purus et nisl dignissim, vel egestas nunc iaculis.
                Maecenas elementum elementum ipsum.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="cmt_right">
              <img src={one} alt="hero image" />
              {/* animated images */}
              <img src={two} alt="hero image" />
              {/* animated images */}
            </div>
          </div>
        </div>
        <div className="row  common_section_padding">
          <div className="col-lg-6">
            <div className="cmt_s4_left">
              <h6>Leaders Leading the way</h6>
              <h3>Join The Leaders Controlling Their Own Destiny</h3>
              <button className="primary_btn">Get Started</button>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="cmt_s4_right">
              <img src={one} alt="one" />
              <img src={one} alt="two" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Community;
