import React from "react";
import face1 from "../../../assets/sectiontwo/face1.png";
import face2 from "../../../assets/sectiontwo/face2.png";
import face3 from "../../../assets/sectiontwo/face3.png";
import face4 from "../../../assets/sectiontwo/face4.png";
import face5 from "../../../assets/sectiontwo/face5.png";
import face6 from "../../../assets/sectiontwo/face6.png";
import face7 from "../../../assets/sectiontwo/face7.png";
import face8 from "../../../assets/sectiontwo/face8.png";
import all from "../../../assets/sectiontwo/all.png";
import icon1 from "../../../assets/sectiontwo/icon1.png";
import icon2 from "../../../assets/sectiontwo/icon2.png";


import "./SectionTwo.scss";

const SectionTwo = () => {
  return (
    <div className="common_top_padding">
      <div className="st_main_container">
        <div className="container">
          <div className="row st_container">
            <div className="col-lg-6 my_grid_col">
              <div className="st_left">
                <div className="st_left_icon">
                  <img src={icon1} alt="icon1" />
                  <h3 className="st_left_downloads font_weight_800">
                    10K+ Clients
                  </h3>
                </div>
                <div className="st_left_icon">
                  <img src={icon2} alt="icon1" />
                  <h3 className="st_left_users font_weight_800">
                    8 branch office
                  </h3>
                </div>
              </div>
            </div>

            {window.innerWidth > 1050 ? (
              <div className="col-lg-6 my_grid_col">
                <div className="st_right">
                  <img data-aos="flip-down" src={face1} alt="face1" />
                  <img src={face2} alt="face2" />
                  <img src={face3} alt="face3" />
                  <img src={face4} alt="face4" />
                  <img src={face5} alt="face5" />
                  <img src={face6} alt="face6" />
                  <img src={face7} alt="face7" />
                  <img src={face8} alt="face8" />
                </div>
              </div>
            ) : (
              <div className="col-lg-6">
                <div className="st_all_right">
                  <img src={all} alt="all" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
