import React, { useState } from "react";
import Header from "../Header/Header";
import left from "../../assets/auth/left.png";
import right from "../../assets/auth/right.png";
import playvideo from "../../assets/auth/PlayVideo.png";
import "./Register.scss";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";



const Register = () => {
  const [userid, setUserid] = useState("");
  const [password, setPassword] = useState("");
  const [metamask, setMetamask] = useState("");
  const [email, setEmail] = useState("");



  const submitDetails =  () => {
   
    if(metamask.length<16){
      alert("please enter a valid metamask id")

    } else if(!email.includes('@')){
      alert("please enter a valid email id")
    } else {

    
 var postData = {
      mobileNumber: userid,
      password: password,
      metamaskID:metamask,
      kycEmail:email
    };
    
    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
      }
    };
    
    axios.post('https://cc-iaaa-bs.com/api/cc-admin/signup/v1', postData, axiosConfig)
    .then((res) => {
      console.log("RESPONSE RECEIVED: ", res);

      localStorage.setItem('userid', res.data.data.userID);
      localStorage.setItem('mobileno', res.data.data.mobileNumber);


     localStorage.setItem('Authorization', "Bearer "+res.data.data.accessToken);
     console.log("RESPONSE RECEIVED auth: ", localStorage.getItem('Authorization'));
     navigate("/KYC") 

   
    })
    .catch((err) => {
      alert("An account with the same User ID or Email ID is already registered. Please try with a different User ID or Email ID to continue.");
    })
  } 
  
  }



  const checkMetamask =  (e) => {
    e.preventDefault()
    if(metamask.length<16){
      alert("please enter a valid metamask id")
    } else if(!email.includes('@')){
      alert("please enter a valid email id")
    } else {
    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
      }
    };
    axios.get('https://cc-iaaa-bs.com/api/cc-admin/referraluser?metamaskID='+metamask,  axiosConfig)
    .then((res) => {
      console.log("RESPONSE RECEIVED get: ", res);
      if(res.data.success==false){
        alert("The Metamask ID entered by you already exists. Please enter a new Metamask ID");
        //submitDetails();
      } else{
        console.log("hello falsr")
        submitDetails();
      }
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }
  }










  const navigate = useNavigate();
  return (
    <div>
      <Header />
      <div className="auth_bg">
        <div className="login_title login_top_padding container">
          <h3>Welcome to the Future</h3>
          <p>
            CitizenChat is now a Tech-Fin, entered into the world of Blockchain,
            offering security tokens (Equity <br /> Tokens). 100% of CitizenChat
            tokens represent 100% ownership of the CitizenChat platform
          </p>
        </div>
        <div className="login_body ">
          <div className="cc_form register container">
            <form className="cc_form_container">
              <div className="cc_form_header text-center">
                <h5>Register To Buy Tokens</h5>
                <p>Create account with your user id</p>
              </div>
               
               <div className="videodiv">
               <p>Watch the KYC demo video</p>
               <img className="videobtn" src={playvideo} alt="play" 
               onClick={() => {
                window.open("https://youtu.be/GZt_i5uUnU4", "_self");
              }} />
               </div>

              <div className="cc_form_id">
                <label for="fname">Enter UserID</label>
                <input
                  onChange={(e) => setUserid(e.target.value)}
                  required
                  type="text"
                  id="fname"
                  name="fname"
                  value={userid}
                />
              </div>
              <div className="cc_form_pwd">
                <label for="lname">Enter Password</label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  type="password"
                  id="lname"
                  name="lname"
                  value={password}
                />
              </div>
              <div className="cc_form_id">
                <label for="fname">Enter Metamask ID</label>
                <input
                  onChange={(e) => setMetamask(e.target.value)}
                  required
                  type="text"
                  id="fname"
                  name="fname"
                  value={metamask}
                />
              </div>
              <div className="cc_form_id">
                <label for="fname">Enter Email ID</label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  type="email"
                  id="fname"
                  name="fname"
                  value={email}
                />
              </div>
              <button 
                              onClick={checkMetamask}

              className="primary_btn" type="submit" value="Submit">
                Register for Free
              </button>
              <p className="login_new_register text-center">
                Already have an account?{" "}
                <Link className="feature_link" to="/login">
                  <span>Log In</span>
                </Link>
              </p>
            </form>
            <img className="left_img" src={left} alt="left" />
            <img className="right_img" src={right} alt="right" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
