import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
// import DropdownServices from "./DropdownServices";
// import apric from "../../assets/icons/apric.png";
import logon from "../../assets/logon.png";

// import logo from "../../assets/icons/logo.png";
// import apricForm from "../../assets/icons/apricForm.png";
// import DropdownProduct from "./DropdownProduct";
// import DropdownCompany from "./DropdownCompany";
import { BiMenuAltRight, BiX } from "react-icons/bi";
import "./Navbar.scss";

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown3, setDropdown3] = useState(false);
  const [responsive, setResponsive] = useState(false);
  const [navToggle, setNavToggle] = useState(false);
  const [navToggle2, setNavToggle2] = useState(false);
  const [navToggle3, setNavToggle3] = useState(false);
  const [webArrow, setWebArrow] = useState(false);
  const [webArrow2, setWebArrow2] = useState(false);
  const [webArrow3, setWebArrow3] = useState(false);
  const [navColor, setNavColor] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setClick(false);
    setNavToggle(false);
  };

  const onMouseEnter = () => {
    if (window.innerWidth < 1050) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 1050) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const onMouseEnter2 = () => {
    if (window.innerWidth < 1050) {
      setDropdown2(false);
    } else {
      setDropdown2(true);
    }
  };

  const onMouseLeave2 = () => {
    if (window.innerWidth < 1050) {
      setDropdown2(false);
    } else {
      setDropdown2(false);
    }
  };

  const onMouseEnter3 = () => {
    if (window.innerWidth < 1050) {
      setDropdown3(false);
    } else {
      setDropdown3(true);
    }
  };

  const onMouseLeave3 = () => {
    if (window.innerWidth < 1050) {
      setDropdown3(false);
    } else {
      setDropdown3(false);
    }
  };

  // const changeNavColor = () => {
  //   if (window.scrollY >= 60) {
  //     setNavColor(true);
  //   } else {
  //     setNavColor(false);
  //   }
  // };

  // window.addEventListener("scroll", changeNavColor);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1050
        ? setResponsive(true)
        : setResponsive(false);
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  let menuRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (window.innerWidth < 1050 && !menuRef.current.contains(event.target)) {
        closeMobileMenu();
      }
    });
  });

  const desktopDisplay = () => {
    return (
      <ul className={click ? "nav-menu active" : "nav-menu"}>
        {/* <li
          className="nav-item"
          onMouseEnter={onMouseEnter2}
          onMouseLeave={onMouseLeave2}
          onPointerOver={() => {
            setWebArrow(true);
          }}
          onPointerOut={() => {
            setWebArrow(false);
          }}
          onClick={() => {
            setWebArrow(false);
          }}
        >
          <NavLink
            to="#"
            className="scroll_nav_links"
            activeClassName="nav-links active scroll_active_border_bottom"
          >
            Products&nbsp;&nbsp;
            <div className={webArrow ? "dd_mobile_180 d-flex" : "d-flex"}>
              <ion-icon name="chevron-down-outline" />
            </div>
          </NavLink>
          {dropdown2 && (
            <DropdownProduct setDropdown={setDropdown2} setClick={setClick} />
          )}
        </li> */}
        <li
          className="nav-item"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onPointerOver={() => {
            setWebArrow2(true);
          }}
          onPointerOut={() => {
            setWebArrow2(false);
          }}
        >
          <NavLink
            to="/home"
            className={(navdata) =>
              navdata.isActive
                ? "scroll_nav_links scroll_active_border_bottom"
                : "scroll_nav_links"
            }
          >
            Home&nbsp;&nbsp;
            {/* <div className={webArrow2 ? "dd_mobile_180 d-flex" : "d-flex"}>
              <ion-icon name="chevron-down-outline" />
            </div> */}
          </NavLink>
          {/* <span className="nav-links">Services</span> */}
          {/* {dropdown && (
            <DropdownServices setDropdown={setDropdown} setClick={setClick} />
          )} */}
        </li>
        <li className="nav-item">
          <NavLink
            to="/about-us"
            className={(navdata) =>
              navdata.isActive
                ? "scroll_nav_links scroll_active_border_bottom"
                : "scroll_nav_links"
            }
          >
            About Us
          </NavLink>
        </li>
        <li
          className="nav-item"
          onMouseEnter={onMouseEnter3}
          onMouseLeave={onMouseLeave3}
          onPointerOver={() => {
            setWebArrow3(true);
          }}
          onPointerOut={() => {
            setWebArrow3(false);
          }}
          onClick={() => {
            setWebArrow3(false);
          }}
        >
          <NavLink
            to="/features"
            className={(navdata) =>
              navdata.isActive
                ? "scroll_nav_links scroll_active_border_bottom"
                : "scroll_nav_links"
            }
          >
            Services&nbsp;&nbsp;
            {/* <div className={webArrow3 ? "dd_mobile_180 d-flex" : "d-flex"}>
              <ion-icon name="chevron-down-outline" />
            </div> */}
          </NavLink>
          {/* {dropdown3 && (
            <DropdownCompany setDropdown={setDropdown3} setClick={setClick} />
          )} */}
        </li>
        {/* <li className="nav-item">
          <NavLink
            to="community"
            className="scroll_nav_links"
            activeClassName="nav-links active scroll_active_border_bottom"
          >
            Community
          </NavLink>
        </li> */}


        {/* <li className="nav-item">
          <NavLink
            to="/cc-coin"
            className={(navdata) =>
              navdata.isActive
                ? "scroll_nav_links scroll_active_border_bottom"
                : "scroll_nav_links"
            }
          >
            CC Coin
          </NavLink>
        </li> */}


        {/* <li className="nav-item airdropbtn"  onClick={() => {
              window.open("https://citizenchat.com/ccform/", "_self");
            }}>
      
            CC Airdrop
        </li> */}

        {/* <li className="nav-item">
          <Link
            to="//onelink.to/ctznchat"
            target="_blank"
            className="primary_btn"
          >
            Download Now
          </Link>
        </li> */}
      </ul>
    );
  };

  const mobileDisplay = () => {
    return (
      <ul ref={menuRef} className={click ? "nav-menu active " : "nav-menu "}>
        <div className="mobile_navbar_logo">
          <NavLink to="/" onClick={closeMobileMenu}>
            <img src={logon} alt="apric mobile logo" />
          </NavLink>
        </div>
        <div className="d-flex align-items-center">
          <li className="nav-item">
            <Link to="/home" className="nav-links" onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          {/* <div className="d-flex">
            <ion-icon
              onClick={() => {
                setNavToggle2(!navToggle2);
                setNavToggle(false);
                setNavToggle3(false);
              }}
              name={navToggle2 ? "chevron-up-outline" : "chevron-down-outline"}
            />
          </div> */}
        </div>
        {/* submenu product */}
        {/* <div className={!navToggle2 ? "navbar_subitem" : null}>
          {navToggle2 ? (
            <>
              <li className="nav-subitem">
                <Link
                  to="#"
                  className="nav-links-submenu"
                  onClick={closeMobileMenu}
                >
                  <div className="apricForm_logo dd_option_icon">
                    <img src={apricForm} />
                  </div>
                  Apric Form
                </Link>
              </li>
              <li className="nav-subitem">
                <Link
                  to="#"
                  className="nav-links-submenu"
                  onClick={closeMobileMenu}
                >
                  <div className="dd_option_icon d-flex">
                    <ion-icon name="trending-up-outline"></ion-icon>
                  </div>
                  Apric Sales
                </Link>
              </li>
            </>
          ) : null}
        </div> */}
        {/* submenu product */}
        <div className="d-flex align-items-center">
          <li className="nav-item ">
            <Link
              to="/about-us"
              className="nav-links text-dark"
              onClick={closeMobileMenu}
            >
              About Us
            </Link>
          </li>
          {/* <div className="d-flex">
            <ion-icon
              onClick={() => {
                setNavToggle(!navToggle);
                setNavToggle3(false);
                setNavToggle2(false);
              }}
              name={navToggle ? "chevron-up-outline" : "chevron-down-outline"}
            />
          </div> */}
        </div>
        {/* submenu */}
        <div className={!navToggle ? "navbar_subitem" : null}>
          {navToggle ? (
            <>
              <li className="nav-links-submenu-title">Consulting</li>
              <li className="nav-subitem">
                <Link
                  to="#"
                  className="nav-links-submenu"
                  onClick={closeMobileMenu}
                >
                  {/* <div className="dd_option_icon d-flex">
                    <ion-icon name="git-compare-outline"></ion-icon>
                  </div> */}
                  Strategy
                </Link>
              </li>
              <li className="nav-subitem">
                <Link
                  to="#"
                  className="nav-links-submenu"
                  onClick={closeMobileMenu}
                >
                  <div className="dd_option_icon d-flex">
                    <ion-icon name="diamond-outline"></ion-icon>
                  </div>
                  Architecture
                </Link>
              </li>
              <li className="nav-links-submenu-title mt-2">Design</li>
              <li className="nav-subitem">
                <Link
                  to="#"
                  className="nav-links-submenu"
                  onClick={closeMobileMenu}
                >
                  <div className="dd_option_icon d-flex">
                    <ion-icon name="megaphone-outline"></ion-icon>
                  </div>
                  Branding
                </Link>
              </li>
              <li className="nav-subitem">
                <Link
                  to="#"
                  className="nav-links-submenu"
                  onClick={closeMobileMenu}
                >
                  <div className="dd_option_icon d-flex">
                    <ion-icon name="easel-outline"></ion-icon>
                  </div>
                  UX Design
                </Link>
              </li>
              <li className="nav-subitem">
                <Link
                  to="#"
                  className="nav-links-submenu"
                  onClick={closeMobileMenu}
                >
                  <div className="dd_option_icon d-flex">
                    <ion-icon name="copy-outline"></ion-icon>
                  </div>
                  UI Design
                </Link>
              </li>
              <li className="nav-links-submenu-title mt-2">Technology</li>
              <li className="nav-subitem">
                <Link
                  to="#"
                  className="nav-links-submenu"
                  onClick={closeMobileMenu}
                >
                  <div className="dd_option_icon d-flex">
                    <ion-icon name="id-card-outline"></ion-icon>
                  </div>
                  Web & Mobile Development
                </Link>
              </li>
              <li className="nav-subitem">
                <Link
                  to="#"
                  className="nav-links-submenu"
                  onClick={closeMobileMenu}
                >
                  <div className="dd_option_icon d-flex">
                    <ion-icon name="basket-outline"></ion-icon>
                  </div>
                  E-Commerce System
                </Link>
              </li>
              <li className="nav-subitem">
                <Link
                  to="#"
                  className="nav-links-submenu"
                  onClick={closeMobileMenu}
                >
                  <div className="dd_option_icon d-flex">
                    <ion-icon name="settings-outline"></ion-icon>
                  </div>
                  CMS
                </Link>
              </li>
              <li className="nav-subitem">
                <Link
                  to="#"
                  className="nav-links-submenu"
                  onClick={closeMobileMenu}
                >
                  <div className="dd_option_icon d-flex">
                    <ion-icon name="server-outline"></ion-icon>
                  </div>
                  Data and Analytics
                </Link>
              </li>
            </>
          ) : null}
        </div>
        {/* submenu */}
        <div className="d-flex align-items-center">
          <li className="nav-item ">
            <Link
              to="/features"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Features
            </Link>
          </li>
        </div>
        {/* <div className="d-flex align-items-center">
          <li className="nav-item ">
            <Link
              to="community"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Community
            </Link>
          </li>
        </div> */}
        {/* <div className="d-flex align-items-center"> */}
          {/* <li className="nav-item ">
            <Link to="/cc-coin" className="nav-links" onClick={closeMobileMenu}>
              CC Coin
            </Link>
          </li>
        </div> */}


        {/* <div className="d-flex align-items-center">
          <li className="nav-item " onClick={() => {
              window.open("https://citizenchat.com/ccform/", "_self");
            }}>
            <Link to="" className="nav-links"    onClick={closeMobileMenu}>
              CC Airdrop 
            </Link>
          </li>
        </div> */}
      

        {/* ---- */}
        {/* <div className="d-flex align-items-center">
          <li className="nav-item">
            <Link to="#" className="nav-links" onClick={closeMobileMenu}>
              Company
            </Link>
          </li>
          <div className="d-flex">
            <ion-icon
              onClick={() => {
                setNavToggle3(!navToggle3);
                setNavToggle(false);
                setNavToggle2(false);
              }}
              name={navToggle3 ? "chevron-up-outline" : "chevron-down-outline"}
            />
          </div>
        </div> */}
        {/* submenu product */}
        {/* <div className={!navToggle3 ? "navbar_subitem" : null}>
          {navToggle3 ? (
            <>
              <li className="nav-subitem">
                <Link
                  to="#"
                  className="nav-links-submenu"
                  onClick={closeMobileMenu}
                >
                  <div className="dd_option_icon d-flex">
                    <ion-icon name="information-circle-outline" />
                  </div>
                  Services
                </Link>
              </li>
              <li className="nav-subitem">
                <Link
                  to="/Carrier"
                  className="nav-links-submenu"
                  onClick={closeMobileMenu}
                >
                  <div className="dd_option_icon d-flex">
                    <ion-icon name="person-outline"></ion-icon>
                  </div>
                  Carrier
                </Link>
              </li>
              <li className="nav-subitem">
                <Link
                  to="/ContactPage"
                  className="nav-links-submenu"
                  onClick={closeMobileMenu}
                >
                  <div className="dd_option_icon d-flex">
                    <ion-icon name="call-outline"></ion-icon>
                  </div>
                  Contact Us
                </Link>
              </li>
            </>
          ) : null}
        </div> */}
        {/* submenu product */}
      </ul>
    );
  };

  return (
    <>
      <nav className="scroll_my_navbar_main">
        <div className="scroll_my_navbar container">
          <NavLink
            to="/"
            onClick={closeMobileMenu}
            className="my_navbar_logo nav-links"
          >
            <div className="my_navbar_logo_div">
              <img src={logon} alt="logo" />
            </div>
          </NavLink>
          <div className="scroll_menu_icon" onClick={handleClick}>
            {click ? <BiX color="red" /> : <BiMenuAltRight />}
          </div>
          {responsive ? mobileDisplay() : desktopDisplay()}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
