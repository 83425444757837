import React from "react";
import "./SectionFour.scss";
import stars from "../../../assets/sectionone/star1.png";

const SectionFour = () => {
  return (
    <div className="container common_section_padding">
      <div className="row">
        <div className="col lg-6 flex_center">
          <div className="s4_left mob_flex_center">
            <h6 className="cc_text_transform">WE HELP YOU TO</h6>
            <h3 className="font_weight_800">Become Famous</h3>
            <p>
              With a huge audience already on the app, CitizenChat helps you
              become an influencer and make the best Donec lacinia purus et nisl
              dignissim, vel.
            </p>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="s4_right">
            <div className="s4_right_one up">
              <div data-aos="flip-left">
                <h2>50k+</h2>
                <h5>Downloads from App Store</h5>
              </div>
              <div data-aos="flip-left">
                <h2>2M+</h2>
                <h5>Downloads from Play Store</h5>
              </div>
            </div>
            <div className="s4_right_two down">
              <div data-aos="flip-left">
                <h2>40k+</h2>
                <h5>Active Users</h5>
              </div>
              <div data-aos="flip-left">
                <h2 className="revieratings">8k+</h2>
              
                <div className="ratingstars">
                  <img className="stars" src={stars} alt="stars" />
                  <img className="stars" src={stars} alt="stars" />
                  <img className="stars" src={stars} alt="stars" />
                  <img className="stars" src={stars} alt="stars" />
                  <img className="stars" src={stars} alt="stars" />
                  <h5>Reviews</h5>
                  </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFour;
