import React from "react";
import google from "../../../assets/hero/google.png";
import apple from "../../../assets/hero/apple.png";
import one from "../../../assets/hero/one.png";
import two from "../../../assets/hero/two.png";
import b1 from "../../../assets/hero/b1.png";
import b2 from "../../../assets/hero/b2.png";
import b3 from "../../../assets/hero/b3.png";
import b4 from "../../../assets/hero/b4.png";
import v1 from "../../../assets/hero/v1.jpg";

import matic from "../../../assets/hero/matic.png";
import bitcoin from "../../../assets/hero/Bitcoin.png";
import ethereum from "../../../assets/hero/Ethereum.png";
import usdt from "../../../assets/hero/USDT.png";



import "./Hero.scss";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="container common_top_padding ">
      <div className="row margin_top_mob common_top_padding">
        <div className="col-lg-6">
          <div className="hero_left">
            <h6 className="cc_text_transform">JM & Associates</h6>
            <h1 className="font_weight_800">
            Our unique business model aims at delivering customized tailored solutions and services
                      </h1>
            <p>
            JM & Associates shall be a leader in providing high-quality services in the field of Audit and Assurance, Accounting, Tax and Business Advisory to domestic and international clients. The firm would have a presence in principal cities of India and would develop niche areas for the future growth of the firm.  
            </p>
            
          </div>
        </div>
        <div className="col-lg-6">
          <div className="hero_right coin_right">
            <img data-aos="zoom-in" src={v1} alt="hero 1" />
            {/* animated images */}
            {/* <img src={two} alt="hero 2" />
            <img src={b1} alt="hero 3" />
            <img src={b2} alt="hero 4" />
            <img src={b3} alt="hero 5" />
            <img src={b4} alt="hero 6" /> */}
            {/* animated images */}
          </div>
        </div>

       
  
        

      
         
        

       
         
      


      </div>
    </div>
  );
};

export default Hero;
