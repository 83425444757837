import React from "react";
import one from "../../assets/features/one.png";
import two from "../../assets/features/two.png";
import three from "../../assets/features/three.png";
import four from "../../assets/features/four.png";
import five from "../../assets/features/five.png";
import six from "../../assets/features/six.png";
import seven from "../../assets/features/seven.png";

import v1 from "../../assets/features/v1.jpg";
import v2 from "../../assets/features/v2.jpg";
import v3 from "../../assets/features/v3.jpg";
import v4 from "../../assets/features/v4.jpg";

import c1 from "../../assets/features/c1.jpeg";

import c2 from "../../assets/features/c2.jpeg";



import "./Features.scss";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const featureData = [
  {
    f_id: "audio",
    f_title: "CMA Soumya Ranjan Jena",
    f_tag: "B.Com., FCMA",
    f_content:
      "CMA Soumya Ranjan Jena became a member of Institute of cost Accountant of India in February 2016. He has rich experience in the field of Direct Taxation, Statutory Audit & Handling Corporate affairs in different areas.",
    f_image: c2,
    f_anime: "flip-left",
    s_id: "multi",
    s_title: "CMA Bibhuti Bhusan Nayak",
    s_tag: "M.Com., MBA, FCMA",
    s_content:
      "Bibhuti Bhusan Nayak became a member of Institute of cost Accountant of India in July 1997. He has a rich experience in the field of Indirect Taxation, Internal Audit, Book keeping services and Handling Management Consultancy Assignments. ",
    s_image: c1,
    s_anime: "flip-right",
  },
  // {
  //   f_id: "edit",
  //   f_title: "Direct Taxation",
  //   f_tag: "Harness your true potential by editing and sharing videos.",
  //   f_content:
  //     "Stand out from the social media noise. The best investment you can make for your social media is videos with uncompromised quality and high-end edits. Enhance your social presence by leveraging our in-app video editor and share your precious moments to millions on the platform.",
  //   f_image: v3,
  //   f_anime: "flip-left",
  //   s_id: "learn",
  //   s_title: "Indirect Taxation",
  //   s_tag: "Transforming lives by advancing knowledge-driven growth.",
  //   s_content:
  //     "Choose from a broad selection of courses to quench your thirst for knowledge. Learn unique skills to achieve your personal and professional goals. Our online courses strive to offer a rich, interactive and profound studying experience - for anyone in the world, regardless of where they are located.",
  //   s_image: v4,
  //   s_anime: "flip-right",
  // },
  // {
  //   f_id: "job",
  //   f_title: "Risk advisory service",
  //   f_tag: "The best place to find end-to-end lucrative selfies.",
  //   f_content:
  //     "Why just take a halt at chatting with your friends? With CitizenChat, you can now search for lucrative job opportunities in various fields of interest. This is not just limited to people looking for a job, if you are looking to recruit, you can post a job offer for free as well. Post the job, talk to the applicants directly and proceed with the selection process.",
  //   f_image: v2,
  //   f_anime: "flip-left",

  //   s_id: "global",
  //   s_title: "Book Keeping and outsourcing",
  //   s_tag: "Quick and efficient search that never dissapoints!",
  //   s_content:
  //     "CitizenChat not only helps you discover people around you, it also helps you discover people and companies all around the world with hobbies, profession and skills similar to yours. We give you the liberty to filter out the language so you can find people who speak the same language as you. The creative possibilities are unlimited. ",
  //   s_image: v1,
  //   s_anime: "flip-right",
  // },
  
];

const Features = () => {
  return (
    <div className="common_bg">
      <Navbar />
      <div className="container common_top_padding">
        <div className="s3_header text-center ">
          <h6 className="cc_text_transform">Ease of</h6>
          <h3 className="font_weight_800">Services we provide</h3>
          <p>
            We belive in continious development and growth.
          </p>
          <div div className="features_tags_container">
            <div className="features_tags">
              <a href="#self" className="feature_link secondary_btn feature">
              Corporate Services
              </a>
              <a href="#edit" className="feature_link secondary_btn feature">
              Audit and Assurance Services


              </a>
              <a href="#audio" className="feature_link secondary_btn feature">
              Direct Taxation


              </a>
              <a href="#learn" className="feature_link secondary_btn feature">
              Indirect Taxation

</a>
            </div>
            <div className="features_tags">
              <a href="#job" className="feature_link secondary_btn feature">
                Job Opportunities
              </a>
              <a href="#global" className="feature_link secondary_btn feature">
              Risk Advisory Services

</a>
              <a href="#multi" className="feature_link secondary_btn feature">
              Book Keeping and Outsourcing

</a>
            </div>
          </div>
        </div>
        {featureData.map((fd, index) => (
          <>
            <div id={fd.f_id} className="row common_section_padding">
              <div className="col-lg-6 flex_center">
                <div className="features_content ">
                  <h6>{fd.f_title}</h6>
                  <h3 className="font_weight_800">{fd.f_tag}</h3>
                  <p>{fd.f_content}</p>
                </div>
              </div>
              <div className="col-lg-6 ">
                <div data-aos={fd.f_anime} className="features_images_left">
                  <img src={fd.f_image} alt="hero 1" />
                  {/* animateds */}
                </div>
              </div>
            </div>
            <div
              id={fd.s_id}
              className="row features_wrapper_two common_section_padding"
            >
              {fd.f_title !== "Selfies" ? (
                <>
                  <div className="col-lg-6 flex_center">
                    <div className="features_content">
                      <h6>{fd.s_title}</h6>
                      <h3 className="font_weight_800">{fd.s_tag}</h3>
                      <p>{fd.s_content}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div data-aos={fd.s_anime} className="features_images_left">
                      <img src={fd.s_image} alt="hero 2" />
                      {/* animated images */}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Features;
