import React from "react";
import one from "../../assets/aboutus/one.png";
import icon1 from "../../assets/aboutus/icon1.png";
import icon2 from "../../assets/aboutus/icon2.png";
import icon3 from "../../assets/aboutus/icon3.png";
import "./AboutUs.scss";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import logos from "../../assets/logos.jpg";


const AboutUs = () => {
  return (
    <div className="common_bg">
      <Navbar />
      <div className="container common_bg common_top_padding ">
        <div className="s3_header text-center ">
          <h6 className="cc_text_transform">ABOUT US</h6>
          <h3 className="font_weight_800">
            “We platform a culture of transperency and Responsibility”
          </h3>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="au_left flex_center">
              <img data-aos="zoom-in" src={logos} alt="star 2" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="au_right">
              <h3 className="font_weight_800 ">About JM & Associates</h3>
              <p>
              JM & Associates has been established in December 2017 in form of a partnership firm having its Head Office at Bhubaneswar and Branch office at sambalpur,Berhampur,Blasore, Anugul,Delhi,Karnataka and Kolkota by eleven qualified cost Accountants, CMA Bibhuti Bhusan Nayak , CMA Soumya Ranjan Jena,CMA Namrata Nayak,CMA Bibhas Ranjan Mishra,CMA Santanu Sahoo , CMA Prasant Kumar Roul, CMA Prasanta kumar Mohanty,CMA Dibyendu Banerjee,CMA Himanish Das,CMA Himanshu Sahoo,CMA Kamal Lochan Nayak.</p>

<p>It is a cost accountant firm rendering comprehensive professional services, which includes audit, management consultancy, tax consultancy, accounting services, secretarial services etc.</p>

<p>The firm represents a combination of specialized skills, which are geared to offers sound financial advice and personalized proactive services. Following the top corporate culture, we believe in delivering accurate, relevant and timely information to the decision makers using industry expertise. We help companies to stay compliant and lawful. We aim at applying intelligence and expertise by providing realistic solutions to complicated business scenarios using analytical skills, functional expertise,and intensive training. Our result oriented and success driven partners believe in providing equal services to clients of all sizes.
              </p>
              {/* <ul>
                <li>Communicate with never seen before features.</li>
                <li>Leverage our in application chat translator.</li>
                <li>Discover people around your location.</li>
                <li>Search for lucrative job opportunities.</li>
              </ul> */}
            </div>
          </div>
        </div>
        <div className="au_bottom_top">
          <div className="text-center common_section_padding">
            <h6 className="cc_text_transform">Our Vision</h6>
            <h3 className="font_weight_800">Together We Make It Happen!</h3>
            <p>
            JM & Associates shall be a leader in providing high-quality services in the field of Audit and Assurance, Accounting, Tax and Business Advisory to domestic and international clients. The firm would have a presence in principal cities of India and would develop niche areas for the future growth of the firm.


            </p>
            <div className="bottom_top_container">
              <div className="bottom_top_element">
                <div className="bottom_top_element_icon">
                  <img src={icon1} alt="star 3" />
                </div>{" "}
                <h4>Our Mission

</h4>
                <p>
                To deliver services in a timely manner, to create value for our clients, to maintain our personal relationships with the clients that are the foundation of our practice, to provide an enjoyable learning experience and an opportunity for growth for all our team members, to grow but never to compromise on our values of honesty and integrity.
                </p>
              </div>
              <div className="bottom_top_element">
                <div className="bottom_top_element_icon">
                  <img src={icon2} alt="star 4" />
                </div>
                <h4>Our Objective

</h4>
                <p>
                Upholding the highest level of professional standards and reputation for integrity, to the platform a culture of transparency and responsibility. strive to succeed by exceeding client expectations of affordability, quality,and time-bound delivery schedules, focusing on the continuous development and collaborative growth.
                </p>
              </div>
              <div className="bottom_top_element">
                <div className="bottom_top_element_icon">
                  <img src={icon3} alt="star 1" />
                </div>{" "}
                <h4>Anywhere, Anytime</h4>
                <p>
                  Reach and connect with millions of people all accross India
                   in the comfort of your location.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="au_bottom common_section_padding bottom">
          <div className="au_bottom_left">
            <h3 className="flex_center font_weight_800">Our Mission</h3>
            <h5>
              Our mission encompasses the idea of creating a community that adds
              value to foster a positive change in the world by bridging gaps
              between people and systems.
            </h5>
          </div>
          <hr />
          <div className="au_bottom_left">
            <h3 className="flex_center font_weight_800">Our Vision</h3>
            <h5>
              We thrive to intensify our user’s progress to help them flourish
              in the digital realm and directly contribute to their success. Our
              approach is for people to build authentic human connections.
            </h5>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
