import React from "react";
import google from "../../../assets/hero/google.png";
import apple from "../../../assets/hero/apple.png";
import one from "../../../assets/sectionsix/one.png";
import "./SectionSeven.scss";

const SectionSeven = () => {
  return (
    <div className="container common_section_padding">
      <div className="row">
        <div className="s7_left col-lg-6">
          <h3 className="font_weight_800">
            Download the app for <br /> Android & iOS
          </h3>
          <p>
            An intelligent and secure communication <br /> and collaboration
            tool
          </p>
          <div className="hero_badge">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.iaaatech.citizenchat&hl=en_IN&gl=US"
            >
              <img src={google} alt="google" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/in/app/citizenchat/id1470080059"
            >
              <img src={apple} alt="apple" />
            </a>
          </div>
        </div>
        <div className="s7_right col-lg-6">
          <img
            data-aos={window.innerWidth > 1050 ? "fade-left" : null}
            src={one}
            alt="mobile 2"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionSeven;
