import React from "react";
import one from "../../assets/coin/hand_phone.png";
import two from "../../assets/coin/hand.png";
import threeD from "../../assets/coin/3d.png";
import three from "../../assets/coin/three.png";
import coin from "../../assets/coin/coin.png";
import l1 from "../../assets/coin/l1.png";
import l2 from "../../assets/coin/l2.png";
import l3 from "../../assets/coin/l3.png";
import top from "../../assets/coin/top.png";
import bottom from "../../assets/coin/bottom.png";
import googleforms from "../../assets/coin/forms2.png";
import instagram from "../../assets/coin/Instagram.png";
import full from "../../../src/assets/timeline/full.png";
import mob_full from "../../../src/assets/timeline/mob_full.png";
import coinIcon1 from "../../../src/assets/coinIcon/one.png";
import coinIcon2 from "../../../src/assets/coinIcon/two.png";
import "./CCCoin.scss";
import SectionSeven from "../../components/ForHome/SectionSeven/SectionSeven";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player'

const CCCoin = () => {
  return (
    <div className="common_bg">
      <Navbar />
      <div className="container common_top_padding">
        <div className="row common_top_padding">
          <div className="col-lg-6">
            <div className="hero_right coin_hero_right">
              <img src={one} alt="hero" />
              <img
                data-aos="zoom-in"
                className="coin_left_hand"
                src={two}
                alt="hero 2"
              />
              <img
                data-aos="zoom-in"
                className="coin_left_hand"
                src={threeD}
                alt="hero 3"
              />
              {/* animated images */}

              {/* animated images */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="coin_right ">
              <h6>CC COIN</h6>
              <h3 className="font_weight_800">The Future is here!</h3>


              <div className="coin_btns airdropbtn">
               
                  <button onClick={() => {
                  window.open("https://citizenchat.com/ccform/", "_self");
                }} className="primary_btn">CC Airdrop</button>
              
                </div>

              {/* <div className="coin_btns">
                <img onClick={() => {
                  window.open("https://docs.google.com/forms/d/e/1FAIpQLSdFq7aO2NRiBCSD8qzfAjIwL_5UAfWuPCStQYMzR1fJtYOCvw/viewform", "_self");
                }}
                  src={googleforms} alt="googleforms" className="airdropbtn_background" />
              </div> */}

              <p className="coinsdescription">
                CitizenChat coins is a blockchain platform fostering a positive
                impact on the world. It is made for visionaries who with the
                right resources want to contribute to inspire millions around
                the world.
              </p>
              <div className="coin_btns ">
                <Link to="/login" className="feature_link">
                  <button className="primary_btn">Buy Now!</button>
                </Link>

                <div className="animals_btns ">
                  <div className="animals_btns1">Secured with</div>
                  <div className="ani  animals_btns2">
                    <img src={coinIcon1} alt="animal 1" />
                    <p>MetaMask</p>
                  </div>
                  <div className="ani animals_btns3">
                    <img src={coinIcon2} alt="animal 2" />
                    <p>Polygon</p>
                  </div>
                </div>
              </div>


            





            </div>
          </div>
          <div data-aos="fade-down" className="col-lg-12 coin_coin_img coinsdroping">
            <img src={coin} alt="coin 1" />
          </div>
        </div>
        <div className="cc_s2_container">
          <div className="cc_s2_header text-center common_section_padding no_top">
            <h6 className="cc_text_transform">OUR STRATEGY</h6>
            <h3 className="font_weight_800">Solution in One Application</h3>
            <p>
              Our strategy will benefit the investors in improved valuation and
              stabilisation of their <br /> holdings. The coins that we are
              going to burn will be updated on a weekly basis.
            </p>
          </div>
          <div className="cc_s2_body">
            <div className="cc_s2_bg">
              <div className="cc_s2_e1">
                <div className="cc_s2_img">
                  <img src={l1} alt="icon 1" />
                </div>
                <div className="">
                  <h6>
                    Total Supply of Tokens <br />
                  </h6>
                  <p>
                    There will be 100,000,000,000,000,000 supply of tokens.{" "}
                  </p>
                </div>
              </div>
              <div className="cc_s2_e2">
                <div className="cc_s2_img">
                  <img src={l2} alt="icon 2" />
                </div>
                <div>
                  <h6>Total Tokens for Sale</h6>
                  <p>
                    The total tokens for sale will be numbered at
                    30,000,000,000,000,000
                  </p>
                </div>
              </div>
              <div className="cc_s2_e3">
                <div className="cc_s2_img">
                  <img src={l3} alt="icon 3" />
                </div>
                <div>
                  <h6>Total Coin Burns</h6>
                  <p>
                    To regulate the supply and stabilise the price of the Coins,
                    we are going to burn the coins.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cc_s3_container">
          <div className="cc_s2_header text-center common_section_padding">
            <h6 className="cc_text_transform">How this works</h6>
            <h3 className="font_weight_800">Steps to buy tokens</h3>
            <p>
              CitizenChat offers a simple, safe and speedy way to invest and
              trade in digital assets for <br /> you to experience guaranteed
              growth in the days to come.
            </p>
          </div>
          {window.innerWidth > 1050 ? (
            <>
              <div className="cc_s3_body">
                <div className="cc_s3_body_top">
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    className="cc_s3_body_top1"
                  >
                    <h1>01</h1>
                    <h5>KYC Registration</h5>
                    <p>
                      In order to purchase CitizenChat Coins, it is mandatory to
                      complete KYC registration.
                    </p>
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    className="cc_s3_body_top1"
                  >
                    <h1>03</h1>
                    <h5>
                      Enter the quantity of coins you wish to purchase and
                      checkout with your Metamask wallet
                    </h5>
                    <p>
                      Enter the quantity of coins you wish to purchase and
                      checkout with your Metamask wallet
                    </p>
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="3000"
                    className="cc_s3_body_top1"
                  >
                    <h1>05</h1>
                    <h5>Buy CC coin from CitizenChat.com portal</h5>
                    <p>
                      In order to purchase CitizenChat Coins, it is mandatory to
                      complete KYC registration.
                    </p>
                  </div>

                  <img className="top_img1" src={top} alt="top 1" />
                  <img className="top_img2" src={top} alt="top 2" />
                </div>
                <div className="cc_s3_body_bottom">
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    className="cc_s3_body_bottom1"
                  >
                    <h1>02</h1>
                    <h5>Install Metamask Extension in Google Chrome</h5>
                    <p>
                      After successful approval of KYC, at your 1st login,
                      install the Metamask extension when prompted
                    </p>
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    className="cc_s3_body_bottom1"
                  >
                    <h1>04</h1>
                    <h5>
                      Buy Matic in Binance Exchange and transfer to your
                      Metamask
                    </h5>
                    <p>
                      After purchasing, import tokens by pasting the Contract
                      Address: Oxd8595b2e971 baa23 ed79ba0015e7c816d35 0a22f in
                      custom tokens
                    </p>
                  </div>
                  <img className="bottom_img1" src={bottom} alt="bottom 1" />
                  <img className="bottom_img2" src={bottom} alt="top 2" />
                </div>
              </div>
            </>
          ) : (
            <div>
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                className="cc_s3_body_top1 text-center"
              >
                <h1>01</h1>
                <h5>KYC Registration</h5>
                <p>
                  In order to purchase CitizenChat Coins, it is mandatory to
                  complete KYC registration.
                </p>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                className="cc_s3_body_bottom1 text-center"
              >
                <h1>02</h1>
                <h5>Install Metamask Extension in Google Chrome</h5>
                <p>
                  After successful approval of KYC, at your 1st login, install
                  the Metamask extension when prompted
                </p>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="2000"
                className="cc_s3_body_top1 text-center"
              >
                <h1>03</h1>
                <h5>
                  Enter the quantity of coins you wish to purchase and checkout
                  with your Metamask wallet
                </h5>
                <p>
                  Enter the quantity of coins you wish to purchase and checkout
                  with your Metamask wallet
                </p>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="2000"
                className="cc_s3_body_bottom1 text-center"
              >
                <h1>04</h1>
                <h5>
                  Buy Matic in Binance Exchange and transfer to your Metamask
                </h5>
                <p>
                  After purchasing, import tokens by pasting the Contract
                  Address: Oxd8595b2e971 baa23 ed79ba0015e7c816d35 0a22f in
                  custom tokens
                </p>
              </div>
              <div
                data-aos="fade-up"
                data-aos-duration="3000"
                className="cc_s3_body_top1 text-center"
              >
                <h1>05</h1>
                <h5>Buy CC coin from CitizenChat.com portal</h5>
                <p>
                  In order to purchase CitizenChat Coins, it is mandatory to
                  complete KYC registration.
                </p>
              </div>
            </div>
          )}
          {/* skiped section */}
          <div className="cc_timeline common_section_padding">
            <h6 className="cc_text_transform">OUR ROADMAP</h6>
            <h3 className="font_weight_800">
              Strategic Plan Defining Our Goals
            </h3>
            <img
              data-aos="zoom-in"
              src={window.innerWidth > 1050 ? full : mob_full}
              alt="timeline 1"
            />
          </div>
          {/* skiped section */}
          <div className="cc_s4_container common_section_padding">
            <div className="row">
              <div className="col-lg-6 cc_s4_left">
                <h6 className="cc_text_transform">HOW IT WORKS</h6>
                <h3 className="font_weight_800">
                  Buy CitizenChat Coins <br /> Now!
                </h3>
                <p>
                  Watch the short video to know more about CC coins and how to
                  buy them. You will also know the benefits of purchasing the CC
                  coins
                </p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://citizenchat.com/files/ccwp.pdf"
                >
                  <button className="primary_btn">White Paper</button>
                </a>
              </div>
              <div className="col-lg-6">
                {/* <img
                  className="cc_s4_img"
                  data-aos="zoom-in"
                  src={three}
                  alt="zoom"
                /> */}


                <ReactPlayer className="cc_s4_img" width='100%'
                  height='100%' controls url='https://www.youtube.com/watch?v=-gu_zUKPRhM' />

              </div>
            </div>
          </div>
          <SectionSeven />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CCCoin;
