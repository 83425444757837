import React, { useState } from "react";
import Header from "../Header/Header";
import left from "../../assets/auth/left.png";
import right from "../../assets/auth/right.png";
import "./BitcoinLogin.scss";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';

const BitcoinLogin = () => {

//     const [bitcoins, setBitcoins] = useState("");
//     const [cccoins, setCccoins] = useState("");
//     const [metamask, setMetamaskid] = useState("");
//     const [transaction, setTransactionid] = useState("");
//     const [email, setEmail] = useState("");


//     const checkBitcoinLogin = (e) => {
//        e.preventDefault()

//         if(metamask.length<16){
//             alert("please enter a valid metamask id")
      
//           }  else if(bitcoins.length<1){
//             alert("please enter bitcon transferred")
//           } 

//           else if(transaction.length<8){
//             alert("please enter a valid transactionId")
//           } 
          
//           else if(!email.includes('@')){
//             alert("please enter a valid email id")
//           }  else{

//         var postData = {
//             metamaskID: metamask,
//             transactionID:transaction,
//             currencyTransferred:bitcoins,
//             coinsToBeTransferred:cccoins,
//             email:email,
//         };

//         let axiosConfig = {
//             headers: {
//                 'Content-Type': 'application/json;charset=UTF-8',
//             }
//         };

//         axios.post('https://cc-iaaa-bs.com/api/cc-admin/recoverotpverification/v4', postData, axiosConfig)
//             .then((res) => {
//                 console.log("RESPONSE RECEIVED: ", res);
//                 console.log("res data:", res.data.data);

//                 // if (res.data.success == false) {
//                 //     alert(res.data.message);

//                 // }

//                 console.log("success data:", res.data.success);
//                 console.log("message data:", res.data.message);

              

             
               



//             })
//             .catch((err) => {
//                 // alert(res.data.message);
//                 // alert("No account found with the given User ID/Password. Please register your account and complete KYC process to continue.");
//                 // console.log("AXIOS ERROR: ", err);
//             })
//         }

//     }

//     const copyToClipBoard = async copyMe => {
//         try {
//           await navigator.clipboard.writeText(copyMe);
//          // setCopySuccess('Copied!');
//         } catch (err) {
//           //setCopySuccess('Failed to copy!');
//         }
//       };

//     const checkCoinPrice =  (e) => {
//     //    // e.preventDefault()
//         setBitcoins(e.target.value);
//     //    console.log("helocss"+e.target.value);
//     //    localStorage.setItem('btc', e.target.value);

       

        

        
//         let axiosConfig = {
//           headers: {
//               'Content-Type': 'application/json;charset=UTF-8',
//           }
//         };
//         axios.get('https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=BTC,ETH,USDT,BNB,USDC,DOGE',  axiosConfig)
//         .then((res) => {
//           console.log("RESPONSE RECEIVED get: ", res);
//           console.log("RESPONSE RECEIVED get: ", res.data.BTC);
//           console.log("cbyoo"+bitcoins)


//           console.log("hello"+2000000/res.data.BTC);
//           console.log("print"+(2000000/res.data.BTC)*e.target.value);
//           setCccoins((2000000/res.data.BTC)*e.target.value);
//           console.log("cccoin"+cccoins)
         

//         //  checkBitcoinLogin();


        
          
//         })
//         .catch((err) => {
//           console.log("AXIOS ERROR: ", err);
//         })
    
//       }

    
    
    
    
    

   



//     const navigate = useNavigate();
//     return (
//         <>
//             <Header />
//             <div className="auth_bg">
//                 <div className="login_title login_top_padding bottom container">
//                     <h3>Buy CC Coins with Bitcoin</h3>

//                     <p className="stepstobuybtn">Steps to buy</p>



//                     <div className="col-lg-12 stepsrow">

//                         <p className="stepsnumber"><b>01</b></p>
//                         <p className="stepsparagraph">&nbsp; Transfer your desired value of Bitcoin(BTC) to <br />
//                             <b>CitizenChat's contract address</b> that you wish to exchange <br />
//                             with CitizenChat Coins</p>
//                     </div>
//                     <div>
//                         <p className="stepsnumber"><b>02</b></p>
//                         <p className="stepsparagraph">&nbsp; After transferring,copy paste your <b>Metamask ID</b><br />
//                             and <b>Transaction ID</b> in the below form</p>
//                     </div>
//                     <div>
//                         <p className="stepsnumber"><b>03</b></p>
//                         <p className="stepsparagraph">&nbsp; Once verified by our team, yow will receive the <br />corresponding 
//                             quantity of <b>CC Coins
//                             </b> to your Metamask ID</p>
//                     </div>


//                     <div className="row metamaskidrow">
//                         {/* <div className="col-4"></div> */}
//                         <div className="">
//                             <div>
//                                 <button 
//                              onClick={copyToClipBoard('0x569F743A2E282AeA6227967A86Bf4D042AD5962F')}

//                                 className="metamaskid">CitizenchatChat contract address<br /> 0x12eddfds12324412sfsfdfsfsfffdfw1312</button>&nbsp;
//                                 &nbsp; <p className="copybtn">Copy</p>
//                             </div>
//                         </div>
//                         {/* <div className="col-4"></div> */}
//                     </div>

//                 </div>



//                 <div className="login_body ">

//                     <div className="cc_form">

//                         <form className="cc_form_container container">
//                             <div className="cc_form_header text-center">
//                                 <h6>Enter your transaction details</h6>
//                             </div>

//                             <div class="flex-container">

//                                 <div className="cc_form_id flex-child">
//                                     <label for="bitcoinstransferred">Bitcoins Transferred*</label>
//                                     <input

                                   

//                                        // onChange={(e) => setBitcoins(e.target.value)}
//                                         required
//                                         type="text"
//                                         id="bitcoinstransferred"
//                                         name="bitcoinstransferred"
//                                         value={bitcoins}
//                                       //  onChange={(e) => setBitcoins(e.target.value)}

//                                         onChange={e => { setBitcoins(e.target.value);  checkCoinPrice(e) }}

//                                     />
//                                 </div>
//                                 <div className="cc_form_pwd flex-child">
//                                     <label for="coinsreceive">CC Coins you will receive</label>
//                                     <input
//                                         onChange={(e) => setCccoins(e.target.value)}
//                                         required
//                                         type="text"
//                                         id="coinsreceive"
//                                         name="coinsreceive"
//                                         readOnly={props.readonly}
//                                        value={cccoins}
//                                     />
//                                 </div>
//                             </div>
//                             <div class="flex-container">
//                                 <div className="cc_form_pwd flex-child">
//                                     <label for="metamaskid">Metamask ID*</label>
//                                     <input
//                                         onChange={(e) => setMetamaskid(e.target.value)}
//                                         required
//                                         type="text"
//                                         id="metamaskid"
//                                         name="metamaskid"
//                                         value={metamask}
//                                     />
//                                 </div>

//                                 <div className="cc_form_pwd flex-child">
//                                     <label for="transactionid">Transaction ID*</label>
//                                     <input
//                                       //onChange={e => { setTransactionid(e.target.value); checkCoinPrice() }}
//                                         onChange={(e) => setTransactionid(e.target.value)}
//                                         required
//                                         type="text"
//                                         id="transactionid"
//                                         name="transactionid"
//                                         value={transaction}
//                                     />
//                                 </div>
//                             </div>

//                             <div className="cc_form_pwd">
//                                 <label for="emailid">Email ID*</label>
//                                 <input
//                                     onChange={(e) => setEmail(e.target.value)}
//                                     required
//                                     type="text"
//                                     id="emailid"
//                                     name="emailid"
//                                     value={email}
//                                 />
//                             </div>
//                             <button
//                                 onClick={checkBitcoinLogin}
//                                 className="primary_btn"
//                                 type="submit"
//                                 value="Submit"
//                             >
//                                 Submit Details
//                             </button>

//                         </form>
//                         <img className="left_img" src={left} alt="left" />
//                         <img className="right_img" src={right} alt="right" />
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
 };

export default BitcoinLogin;
