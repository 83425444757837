import icon1 from "../assets/sectionone/icon1.png";
import icon2 from "../assets/sectionone/icon2.png";
import icon3 from "../assets/sectionone/icon3.png";
import icon4 from "../assets/sectionone/icon4.png";


export const featureContent = [
  {
    icon: icon1,
    title: "Audit & Assurance Services",
    content: {
      first: "Our assurance services include Statutory audit, Tax audit, Special audits and Investigations.Our professionals provide independent financial statement and internal control audit service  ",
      second: " Our Assurance assignments are conducted in accordance of the engagement standards issued by the ICAI .",
    },
  },
  {
    icon: icon2,
    title: "Book Keeping & Outsourcing",
    content: {
      first: "Book keeping is an art of identifying, approving, sorting and recording company's financial transactions in a custom that they can be retrieved and presented higher management reporting.",
      second: " At JM & Associates our bookkeeping and outsourcing services aims at cost reduction.",
    },
  },
  {
    icon: icon3,
    title: "Direct Taxation",
    content: {
      first: "Direct tax consultancy together with innovative tax efficient strategies, provided by us form an integral part of viable business decisions. ",
      second: "These help our clients attain the desired goals..",
    },
  },
  {
    icon: icon4,
    title: "Risk Advisory Services",
    content: {
      first: "Our risk advisory services specialists help you implement comprehensive solutions to manage your risk environment. Through best practices honed by our experience, you’ll receive strengthened controls and protocols to protect your most valuable information. ",
      second: "The tailored solutions we provide are designed to address your unique risk factors and concerns. Discover how we customize our spectrum of risk advisory services to protect and support your company.",
    },
  },
];
