import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Home from "./pages/Home/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import AboutUs from "./pages/AboutUs/AboutUs";
import Features from "./pages/Features/Features";
import Community from "./pages/Community/Community";
import CCCoin from "./pages/CCCoin/CCCoin";
import Login from "./auth/Login/Login";
import BitcoinLogin from "./auth/BitcoinLogin/BitcoinLogin";
import Register from "./auth/Register/Register";
import KYC from "./auth/KYC/KYC";
import My_Loader from "./components/Loader/My_Loader";
import { useEffect, useState } from "react";
function App() {
  const [homeLoader, setHomeLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setHomeLoader(false);
    }, 3000);
  }, []);
  return (
    <>
      {homeLoader ? (
        <My_Loader />
      ) : (
        <>
          <HashRouter>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Navigate to="home" />} />
              <Route path="/home" element={<Home />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/features" element={<Features />} />
              <Route path="/community" element={<Community />} />
              <Route path="/cc-coin" element={<CCCoin />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/kyc" element={<KYC />} />
              <Route path="/bitcoinlogin" element={<BitcoinLogin />} />
            </Routes>
          </HashRouter>
        </>
      )}
    </>
  );
}
export default App;