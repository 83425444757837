import React, { useState } from "react";
import threeD from "../../assets/coin/3dl.png";
import { css } from "@emotion/react";
import "./My_Loader.css";
import logon from "../../assets/logon.png";


const override = css`
  border-color: red;
`;

const My_Loader = () => {
  let [color, setColor] = useState("#0b1d51");
  return (
    <div className="my_loder_center loader_bg">
      <img className="coin_left_hand coin_loader" src={logon} alt="hero 3" />
      <p className="pt-2">Welcome to Jm & associate</p>
    </div>
  );
};

export default My_Loader;
