import React, { useState } from "react";
import Header from "../Header/Header";
import left from "../../assets/auth/left.png";
import right from "../../assets/auth/right.png";
import "./Login.scss";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';

const Login = () => {
  const [userid, setUserid] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const checkLogin = (e) => {
    e.preventDefault()
    var postData = {
      mobileNumber: email,
      password: password
    };
    
    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json;charset=UTF-8',
      }
    };
    
    axios.post('https://cc-iaaa-bs.com/api/cc-admin/loginwithmail/v4', postData, axiosConfig)
    .then((res) => {
      console.log("RESPONSE RECEIVED: ", res);
      console.log("res data:",res.data.data);
      
      if(res.data.success==false){
        alert(res.data.message);
    
    }

      console.log("success data:",res.data.success);
      console.log("message data:",res.data.message);

      localStorage.setItem('userid', res.data.data.userID);
      localStorage.setItem('mobileno', res.data.data.mobileNumber);
     localStorage.setItem('Authorization', "Bearer "+res.data.data.accessToken);

       if(res.data.data.kycApplied==false){
         alert("Your KYC is incomplete, please submit your KYC");
         navigate("/KYC") ;
    } 
     else if(res.data.data.kycVerified==false){
        alert("Your KYC application verification is pending");
    }else if( res.data.data.kycRejected==true){
      alert("Your KYC application has been rejected. Please contact support@citizenchat.com to know more");
    }
    else if( res.data.data.kycBlocked==true){
      alert("Your account is blocked. Please contact support@citizenchat.com to know more");
    }
    else if(res.data.data.kycApproved==true){
     checkforDeviceTypeLogin();
      // window.location.href = "https://citizenchat.com/ccCoins-buy/docs/index.html";
    }else  if(res.data.data.kycVerified==true && res.data.kycApproved==false){
      alert("Your KYC application Approval is pending");


    }else  if(res.data.data.kycVerified==false && res.data.kycApplied==false){
      alert("Your KYC submission was not completed. Please register again with new User ID and Email ID to submit your KYC.");

  } 
  
   


    })
    .catch((err) => {
      // alert(res.data.message);
      // alert("No account found with the given User ID/Password. Please register your account and complete KYC process to continue.");
      // console.log("AXIOS ERROR: ", err);
    })
    
  }

  const checkforDeviceTypeLogin =  () => {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.location.href="https://metamask.app.link/dapp/citizenchat.com/CC-Coins/docs/index.html"
      /* your code here */
    } else{
      window.location.href = "https://citizenchat.com/ccCoins-buy/docs/index.html";

    }
  }



  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="auth_bg">
        <div className="login_title login_top_padding bottom container">
          <h3>Welcome to the Future</h3>
          <p>
            CitizenChat is now a Tech-Fin, entered into the world of Blockchain,
            offering security tokens (Equity <br /> Tokens). 100% of CitizenChat
            tokens represent 100% ownership of the CitizenChat platform
          </p>
        </div>
        <div className="login_body ">
          <div className="cc_form">
            <form className="cc_form_container container">
              <div className="cc_form_header text-center">
                <h5>Log In to Buy Tokens</h5>
                <p>Login with your CitizenChat user id</p>
              </div>
              <div className="cc_form_id">
                <label for="fname">Enter UserID</label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  type="text"
                  id="fname"
                  name="fname"
                  value={email}
                />
              </div>
              <div className="cc_form_pwd">
                <label for="lname">Enter Password</label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  type="password"
                  id="lname"
                  name="lname"
                  value={password}
                />
              </div>
              <button
                onClick={checkLogin}
                className="primary_btn"
               type="submit"
                value="Submit"
              >
                Log In
              </button>
              <p className="login_new_register text-center">
                New Here?{" "}
                <Link className="feature_link" to="/register">
                  <span>Register For Free</span>
                </Link>
              </p>
            </form>
            <img className="left_img" src={left} alt="left" />
            <img className="right_img" src={right} alt="right" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
