import React, { useState } from "react";
import Header from "../Header/Header";
import left from "../../assets/auth/left.png";
import right from "../../assets/auth/right.png";
import "./KYC.scss";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";



const KYC = () => {
  const [fullname, setFullname] = useState("");
  const [govtId, setGovtId] = useState("");
  const [selfie, setSelfie] = useState("");

    const submitIdKyc =  (e) => {
      e.preventDefault()
      if(fullname.length<3){
        alert("Please enter your Full name and upload Govt.ID proof and selfie photo")
    } else if(govtId.length<3){
      alert("Please upload Govt.ID proof and selfie photo")
    }   else if(selfie.length<3){
      alert("Please upload Govt.ID proof and selfie photo")
    }
    else {
    const formData = new FormData();
    formData.append('file', govtId);
   // formData.append('slefie',selfie);
    //formData.append(fullname,fullname);
let axiosConfig = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    
    axios.post('https://citizenchatkyc.com/upload/file', formData, axiosConfig)
    .then((res) => {
      console.log("RESPONSE RECEIVED: ", res);
      console.log("RESPONSE RECEIVED: ", res.data.fileUrl);

      localStorage.setItem('govtid',res.data.fileUrl)
      console.log("RESPONSE RECEIVED url: ",localStorage.getItem('govtid'));

      submitSelfieKyc();
    

    //  window.location.href="CC-Coins/docs/index.html"

      
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })
  }
    }
  const submitSelfieKyc =  () => {
    //e.preventDefault()
    const formData = new FormData();
    formData.append('file', selfie);   
    
    // formData.append('slefie',selfie);
    //formData.append(fullname,fullname);
let axiosConfig = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    
    axios.post('https://citizenchatkyc.com/upload/file', formData, axiosConfig)
    .then((res) => {
      console.log("RESPONSE RECEIVED: ", res);
      console.log("RESPONSE RECEIVED url: ", res.data.fileUrl);

      localStorage.setItem('selfieid',res.data.fileUrl)
      console.log("RESPONSE RECEIVED urlss: ",localStorage.getItem('selfieid'));

      

      submitfullKyc();
    

    //  window.location.href="CC-Coins/docs/index.html"

      
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);
    })

  }

  const submitfullKyc =  () => {
    //e.preventDefault()
    console.log("RESPONSE RECEIVEDauth: ", localStorage.getItem('Authorization'));
    console.log("RESPONSE RECEIVEDmob: ", localStorage.getItem('mobileno'));
    var postData = {

      mobileNumber:localStorage.getItem('mobileno'),
      userName: fullname,
      userID:localStorage.getItem('userid'),
      kycDocUrl: localStorage.getItem('govtid'),
      selfieUrl: localStorage.getItem('selfieid')
    };
  //  const formData = new FormData();
    //formData.append('govtId', govtId);
   // formData.append('slefie',selfie);
    //formData.append(fullname,fullname);
let axiosConfig = {
      headers: {
        'Authorization': localStorage.getItem('Authorization'),
         'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    
    axios.put('https://cc-iaaa-bs.com/api/cc-admin/kyc/user/uploaddocumnet', postData, axiosConfig)
    .then((res) => {
      console.log("RESPONSE RECEIVED: ", res);
      navigate("/Login") 


    

    //  window.location.href="CC-Coins/docs/index.html"

      
    })
    .catch((err) => {
      console.log("AXIOS ERROR: ", err);


    })

  }

  const navigate = useNavigate();

  
  return (
    <div>
      <Header />
      <div className="auth_bg">
        <div className="login_title login_top_padding container">
          <h3>Welcome to the Future</h3>
          <p>
            CitizenChat is now a Tech-Fin, entered into the world of Blockchain,
            offering security tokens (Equity <br /> Tokens). 100% of CitizenChat
            tokens represent 100% ownership of the CitizenChat platform
          </p>
        </div>
        <div className="login_body ">
          <div className="cc_form register container">
            <form className="cc_form_container">
              <div className="cc_form_header text-center">
                <h5>Complete KYC</h5>
                <p>
                  It is mandatory to complete KYC to buy token as per government
                  guidelines
                </p>
              </div>
              <div className="cc_form_id">
                <label for="fname">Enter Full Name as per KYC</label>
                <input
                  onChange={(e) => setFullname(e.target.value)}
                  required
                  type="text"
                  id="fname"
                  name="fname"
                  value={fullname}
                />
              </div>
              <div className="cc_form_id">
                <label for="govtId">Upload Government approved ID</label>
                <input
                  required
                  type="file"
                  id="govtId"
                  name="govtId"
                  onChange={(ev) => setGovtId(ev.target.files[0])}
                />
              </div>
              
              <div className="cc_form_id">
                <label for="selfie">Upload your selfie</label>
                <input
                  required
                  type="file"
                  id="selfie"
                  name="selfie"
                  onChange={(ev) => setSelfie(ev.target.files[0])}
                />
              </div>
              <button className="primary_btn" type="submit" value="Submit" onClick={submitIdKyc}>
                Submit KYC
              </button>
            </form>
            <img className="left_img" src={left} alt="left" />
            <img className="right_img" src={right} alt="right" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KYC;
